<template>
  <v-container
    fill-height
    :style="!!enabledStepMode ? { padding: 0 } : null"
  >
    <v-layout :align-center="!enabledStepMode" :align-end="enabledStepMode" justify-center align-content-center>
      <v-flex class="justify-center" v-if="currentStep === loginSteps.login" xs12 sm6 md4 lg3>
        <Header v-if="enableAppLogo"/>
        <v-card
            class="justify-center"
            :class="($resize && $mq.above(window.mobileSize) ? 'pa-5' : 'pb-2')"
            :color="loginCardStyleColor"
            :style="loginCardCustomStyle"
        >
          <v-card-text
            :style="enabledStepMode ? { marginBottom: 0, paddingBottom: 0 } :
              { paddingTop: $vuetify.breakpoint.smAndDown ? 10 : 0 }
            "
          >
            <v-form
              id="formlogin"
              ref="formlogin"
              @submit.prevent="login"
              autocomplete="on"
              :lazy-validation="true">
              <p
                v-if="titles.extraTopCardTitle"
                class="extra-title-steps-mode mb-md-10"
                :style="'margin-top: 5px; --extra-title-color: ' + extraTitlesStyle.color
                  + '; --extra-title-font-size: ' + extraTitlesStyle.fontSize"
              >
                {{ titles.extraTopCardTitle }}
              </p>
              <p
                :class="!enabledStepMode ? 'access-account mb-md-10 pt-6' : 'access-account mb-md-10'"
                :style="'--access-account-color: ' + this.accessAccountTitleStyleColor
                  + '; --access-account-font-size: ' + this.accessAccountTitleStyleFontSize"
              >
                {{ validateDevice ? 'Validação de código' : titles.cardTitle }}
              </p>

              <p
                  v-if="validateDevice"
                  :style="'margin-bottom: 5px; margin-top: 20px; --small-title-steps-color: '
                        + smallTitleStepStyle.color + '; --small-title-steps-size: ' + smallTitleStepStyle.fontSize"
                 class="small-title-steps-mode mb-md-10"
              >
                Por favor, digite o código enviado para o email <strong>{{ auth.login }}</strong>:
              </p>

              <v-text-field
                v-if="!enabledStepMode && !validateDevice"
                autofocus
                autocomplete
                clearable
                clear-icon="fas fa-times grey--text"
                name="login"
                id="login"
                label="Usuário"
                placeholder=" "
                type="text"
                v-model="auth.login"
                :rules="loginrules"
                :validate-on-blur="false"
                outlined
                hide-details/>
              <v-text-field
                v-if="!enabledStepMode && !validateDevice"
                clearable
                clear-icon="fas fa-times grey--text"
                name="password"
                id="password"
                label="Senha"
                placeholder=" "
                :type="showpassword ? 'text' : 'password'"
                :append-icon="
                  !showpassword
                    ? 'far fa-eye-slash grey--text'
                    : 'far fa-eye grey--text'
                "
                @click:append="showpassword = !showpassword"
                v-model="auth.password"
                :rules="loginrules"
                :validate-on-blur="false"
                class="mt-3"
                outlined
                hide-details/>
              <v-text-field
                  v-if="validateDevice"
                  autofocus
                  maxlength="5"
                  clearable
                  clear-icon="fas fa-times grey--text"
                  name="confirmationCode"
                  id="confirmationCode"
                  label="Código de confirmação"
                  placeholder=" "
                  type="text"
                  v-model="auth.confirmationCode"
                  :rules="loginrules"
                  :validate-on-blur="false"
                  outlined
                  hide-details/>
              <div
                  v-if="validateDevice && isValidationCodeSent"
                  :style="'margin-top: 20px; --small-title-steps-color: '
                        + smallTitleStepStyle.color + '; --small-title-steps-size: ' + smallTitleStepStyle.fontSize"

              >
                Por favor, aguarde 2 minutos antes de solicitar um novo código: <strong>{{ this.formatCounter() }}</strong>
              </div>

              <v-flex v-if="!enabledStepMode && !validateDevice" v-show="enabledToCreateAccount" class="text-end">
                <a @click="$router.push('redefinir-senha')" class="forgot-password">
                  {{ titles.forgotPasswordTitle }}
                </a>
              </v-flex>
              <Recaptcha
                :siteKey="captchaSiteKey"
                @verify="verify"
                @error="invalidate"
                @expired="invalidate"
                :reload="invalidCredentials"/>
            </v-form>
          </v-card-text>

          <v-card-actions v-if="validateDevice">
            <v-btn block @click.prevent="sendValidationCode" form="formlogin" large :disabled="isValidationCodeSent && timerCount > 0">
              {{ isValidationCodeSent ? 'Reenviar código' : 'Enviar código' }}
            </v-btn>
          </v-card-actions>

          <v-card-actions>
            <v-row v-if="enabledStepMode">
              <v-col cols="6">
                <v-btn
                  block
                  color="primary"
                  large
                  class="login-btn-steps-mode"
                  @click="currentStep = loginSteps.email">
                  {{ titles.loginButtonTitle }}
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  :style="{ color: this.accessAccountTitleStyleColor }"
                  color="secondary"
                  @click="$router.push('cadastro-verificacao')"
                  large
                  class="login-btn-steps-mode">
                  {{ titles.signUpButtonTitle }}
                </v-btn>
              </v-col>
            </v-row>
            <v-btn v-if="!enabledStepMode" block color="primary" type="submit" form="formlogin" large>
              {{ validateDevice ? 'Confirmar código' : titles.loginButtonTitle }}
            </v-btn>
          </v-card-actions>

          <v-card-actions v-if="enabledFaebookLogin">
            <facebook-btn-login/>
          </v-card-actions>

          <v-card-actions v-if="enabledGoogleLogin">
            <google-btn-login/>
          </v-card-actions>

          <v-flex
            v-if="enabledStepMode"
            class="ml-5"
            style="margin-bottom: 10px; margin-top: 20px"
          >
            <a
              @click="$router.push('redefinir-senha')"
              class="extra-title-steps-mode mb-md-10"
              :style="'--extra-title-color: ' + extraTitlesStyle.color + '; --extra-title-font-size: ' + extraTitlesStyle.fontSize"
            >
              {{ titles.forgotPasswordTitle }}
            </a>
          </v-flex>
        </v-card>
        <v-flex
          v-if="!enabledStepMode"
          v-show="enabledToCreateAccount"
          class="No-tem-conta-Cadastre-se text-center mt-5"
          :style="'margin-bottom: 40px; --create-account-color: ' + this.createAccountStyleColor"
        >
          <a @click="$router.push('cadastro-verificacao')">
            <span class="text-style-1">Não tem conta? </span>
            <span class="text-style-2">{{ titles.signUpButtonTitle }}</span>
          </a>
        </v-flex>
      </v-flex>
      <v-flex :style="{ ...gradientBackgroundStyle }" v-if="currentStep > 1" xs12 sm6 md4 lg3>
        <v-row class="my-0 py-0">
          <v-col cols="12" align-self="center" class="my-0 py-0">
            <v-form
                id="formLoginSteps"
                ref="formLoginSteps"
                @submit.prevent="login"
                autocomplete="on"
                :lazy-validation="true"
            >
              <v-stepper
                  class="px-5"
                  :style="{ ...stepsBackgroundStyle, height: '100vh' }"
                  style="overflow: visible !important;"
                  v-model="currentStep"
              >
                <v-stepper-items style="overflow: visible !important;">
                  <v-stepper-content step="2" style="overflow: visible !important;">
                    <v-flex>
                      <p
                          class="small-title-steps-mode mb-md-10"
                          :style="'margin-bottom: 5px; margin-top: 20px; --small-title-steps-color: '
                      + smallTitleStepStyle.color + '; --small-title-steps-size: ' + smallTitleStepStyle.fontSize"
                      >
                        Que bom que você voltou!
                      </p>
                      <p
                          class="big-title-steps-mode mb-md-10"
                          :style="'--big-title-steps-color: ' + bigTitleStepStyle.color
                      + '; --big-title-steps-font-size: ' + bigTitleStepStyle.fontSize"
                      >
                        Informe seu e-mail
                      </p>
                    </v-flex>

                    <v-flex class="email-resize" style="margin-top: 60px;">
                      <v-text-field
                          autofocus
                          autocomplete
                          clearable
                          clear-icon="fas fa-times grey--text"
                          name="login"
                          id="login"
                          placeholder=" "
                          type="text"
                          v-model="auth.login"
                          :rules="loginrules"
                          :validate-on-blur="false"
                          hide-details
                          @keydown.space="(event) => event.preventDefault()"
                      />
                    </v-flex>

                    <v-layout :style="$vuetify.breakpoint.width < 351 && { marginTop: '75%' }" align-end>
                      <v-row align="end">
                        <v-col class="pb-0" cols="12">
                          <v-btn
                              block
                              color="primary"
                              large
                              style="margin-bottom: 10px;"
                              class="login-btn-steps-mode"
                              @click="currentStep = loginSteps.password"
                              :disabled="!auth.login"
                          >
                            Próximo
                          </v-btn>
                        </v-col>

                        <v-col class="pt-0" cols="12">
                          <v-btn
                              @click="currentStep = loginSteps.login"
                              :color="this.accessAccountTitleStyleColor"
                              large
                              block
                              text
                          >
                            Voltar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-stepper-content>

                  <v-stepper-content step="3" style="overflow: visible !important;">
                    <v-flex align="start">
                      <p :style="'margin-bottom: 5px; margin-top: 20px; --small-title-steps-color: '
                        + smallTitleStepStyle.color + '; --small-title-steps-size: ' + smallTitleStepStyle.fontSize"
                         class="small-title-steps-mode mb-md-10"
                      >
                        Estamos quase lá!
                      </p>
                      <p :style="'--big-title-steps-color: ' + bigTitleStepStyle.color
                        + '; --big-title-steps-font-size: ' + bigTitleStepStyle.fontSize"
                         class="big-title-steps-mode mb-md-10"
                      >
                        Digite sua senha
                      </p>
                    </v-flex>

                    <v-flex class="password-resize" style="margin-top: 60px;">
                      <v-text-field
                          clearable
                          clear-icon="fas fa-times grey--text"
                          name="password"
                          id="password"
                          placeholder=" "
                          :type="showpassword ? 'text' : 'password'"
                          :append-icon="
                      !showpassword
                        ? 'far fa-eye-slash grey--text'
                        : 'far fa-eye grey--text'
                    "
                          @click:append="showpassword = !showpassword"
                          v-model="auth.password"
                          :rules="loginrules"
                          :validate-on-blur="false"
                          class="mt-3"
                          hide-details
                      />

                      <v-flex style="margin-top: 20px;">
                        <a
                            @click="$router.push('redefinir-senha')"
                            :style="'--extra-title-color: ' + extraTitlesStyle.color + '; --extra-title-font-size: '
                        + extraTitlesStyle.fontSize"
                            class="extra-title-steps-mode mb-md-10"
                        >
                          {{ titles.forgotPasswordTitle }}
                        </a>
                      </v-flex>
                    </v-flex>

                    <v-layout :style="$vuetify.breakpoint.width < 351 && { marginTop: '55%' }" align-end>
                      <v-row align="end">
                        <v-col class="pb-0" cols="12">
                          <v-btn
                              block
                              color="primary"
                              large
                              style="margin-bottom: 10px;"
                              class="login-btn-steps-mode"
                              type="submit"
                              form="formLoginSteps"
                              :disabled="!auth.password"
                          >
                            Entrar
                          </v-btn>
                        </v-col>
                        <v-col class="pt-0" cols="12">
                          <v-btn
                              @click="currentStep = loginSteps.email"
                              :color="this.accessAccountTitleStyleColor"
                              large
                              block
                              text
                          >
                            Voltar
                          </v-btn>
                        </v-col>
                      </v-row>

                    </v-layout>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-form>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <Footer/>
  </v-container>
</template>



<script>
import { mapMutations, mapGetters, mapState, mapActions } from "vuex";
import { formrules } from "./../../common/formrules";
import Recaptcha from "@/components/recaptcha/index.vue";
import Header from "./Header";
import Footer from "./Footer";
import FacebookBtnLogin from "./FacebookBtnLogin";
import GoogleBtnLogin from "./GoogleBtnLogin";

export default {
  name: "Login",
  components: { Recaptcha, Header, Footer, FacebookBtnLogin, GoogleBtnLogin },
  data: () => ({
    invalidCredentials: false,
    busy: false,
    loginrules: [],
    showpassword: false,
    auth: {
      login: "",
      password: "",
      confirmationCode: "",
    },
    currentStep: 1,
    loginSteps: {
      login: 1,
      email: 2,
      password: 3,
      resetPassword: 4,
      signUp: 5
    },
    base64regex: /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/,
    timerCount: 0,
    timerEnabled: false,
    isValidationCodeSent: false,
  }),
  watch: {
    'auth.login'(newValue) {
      if (newValue) {
        this.auth.login = newValue.toLowerCase();
        this.auth.login = this.auth.login.replaceAll(' ', '');
      }
    },
    validateDevice(newValue) {
      if (newValue) {
        this.sendValidationCode();
      }
    },
  },
  computed: {
    ...mapState([
      "window", "titles", "loginCardStyleColor", "enabledStepMode", "validateDevice","pendingSeconds",
      "loginCardCustomStyle", "extraTitlesStyle", "bigTitleStepStyle",
      "smallTitleStepStyle", "stepsBackgroundStyle", "createAccountStyleColor",
      "accessAccountTitleStyleColor", "accessAccountTitleStyleFontSize", "enabledToCreateAccount",
      "enabledFaebookLogin", "enabledGoogleLogin", "gradientBackgroundStyle", 'enableAppLogo'
    ]),
    ...mapGetters(["user", "getUserAuth"]),
    loginLocked() {
      const noFormData =
        !this.auth ||
        !this.auth.login ||
        !this.auth.login.length ||
        !this.auth.password ||
        !this.auth.password.length;
      if (this.invalidCredentials && !noFormData) {
        return true;
      }

      return noFormData;
    },
    captchaSiteKey() {
      return process.env.VUE_APP_CAPTCHA_SITE_KEY;
    },
  },
  methods: {
    ...mapMutations(["showmsg", "loading", "enabledStepModeOn"]),
    ...mapActions(["setValidateDevice"]),
    login() {
      sessionStorage.removeItem('CACHE_CLEAR')
      this.loginrules.push(formrules.required);

      if (this.$refs.formlogin && !this.$refs.formlogin.validate()) {
        setTimeout(() => {
          this.loginrules.splice(0, this.loginrules.length);
          this.$refs.formlogin.resetValidation();
        }, 3000);
        return;
      }

      if (this.$refs.formLoginSteps && !this.$refs.formLoginSteps.validate()) {
        setTimeout(() => {
          this.loginrules.splice(0, this.loginrules.length);
          this.$refs.formLoginSteps.resetValidation();
        }, 3000);
        return;
      }

      this.busy = true;
      this.invalidCredentials = false;
      this.loading(true);

      this.$store.commit('auth', this.auth);
      if (this.validateDevice) {
        this.confirmValidationCode();
      } else {
        this.$store.dispatch("serviceLogin", this.auth)
            .finally(() => {
              this.busy = false;
              this.loading(false);
            });
      }
    },
    verify() {
      this.invalidCredentials = false;
    },
    invalidate() {
      this.invalidCredentials = true;
    },
    isBiometricLogin() {
      return this.$route.query &&  this.$route.query.appCode === 'eu_protegido' && this.$route.query.userEmail && this.$route.query.userPassword;
    },
    startCounter(seconds) {
      this.timerCount = seconds;
      this.timerEnabled = true;
      this.timerTick();
    },
    timerTick() {
      if (this.timerEnabled) {
        setTimeout(() => {
          if (this.timerCount > 0) {
            this.timerCount--;
            this.timerTick();
          } else {
            this.timerEnabled = false;
          }
          console.log(this.timerCount);
        }, 1000);
      }
    },
    async sendValidationCode() {
      if (this.getUserAuth) {
        this.auth = this.getUserAuth;
      }
      this.busy = true;
      this.loading(true);

      this.$store.dispatch("serviceSendValidationCode", this.auth.login)
          .then((response) => {
            if (!response.error) {
              this.isValidationCodeSent = true;
              this.startCounter(response.pendingSeconds ? response.pendingSeconds : 120);
            }
          })
          .finally(() => {
            this.busy = false;
            this.loading(false);
          });
    },
    async confirmValidationCode() {
      this.loading(true);
      this.busy = true;

      const payload = {
        email: this.auth.login,
        validationCode: this.auth.confirmationCode,
      };
      this.$store.dispatch("serviceConfirmValidationCode", payload)
          .then((response) => {
            if (!response.error) {
              this.$store.dispatch("serviceLogin", this.auth)
                  .finally(() => {
                    this.busy = false;
                    this.loading(false);
                  });
            }
          })
          .finally(() => {
            this.busy = false;
            this.loading(false);
          });
    },
    formatCounter() {
      let min, sec;
      if (this.timerCount >= 120) {
        min = '02';
        sec = this.timerCount - 120;
      } else if (this.timerCount >= 60) {
        min = '01';
        sec = this.timerCount - 60;
      } else {
        min = '00';
        sec = this.timerCount;
      }
      sec = sec < 10 ? '0' + sec : sec;
      return min + ':' + sec;
    },
  },
  beforeMount() {
    this.busy = false;
    this.$store.commit("callbackServiceLogin", this.$route.query.callback);
    this.$store.commit("userRole", this.$route.query.userRole);
    this.$store.commit("appCode", this.$route.query.appCode);
    this.$store.commit('appVersion', this.$route.query.appVersion);
    this.$store.dispatch('setTheme', this);

    if (this.isBiometricLogin()) {
      this.busy = true;
      this.loading(this.busy);
      this.auth.password = this.base64regex.test(this.$route.query.userPassword) ? atob(this.$route.query.userPassword) : this.$route.query.userPassword;
      this.auth.login = this.$route.query.userEmail;
      this.currentStep = this.loginSteps.password;
      this.login();
    }
  },
  mounted() {
    document.documentElement.style.overflow = 'visible !important';
  }
};
</script>

<style scoped>
  /* Workarround https://github.com/vuetifyjs/vuetify/issues/13599 */
  .v-btn--disabled {
    pointer-events: unset !important;
    cursor: default;
  }

  /* large */
  @media (min-height: 670px) and (min-width: 411px){
    .email-resize {
      margin-bottom: 350px;
    }

    .password-resize {
      margin-bottom: 300px;
    }
  }

  /* medium */
  @media (min-height: 700px) and (min-width: 350px) and (max-width: 410px){
    .email-resize {
      margin-bottom: 350px;
    }

    .password-resize {
      margin-bottom: 300px;
    }
  }

  /* small */
  @media (max-height: 699px) and (min-width: 350px) and (max-width: 410px){
    .email-resize {
      margin-bottom: 250px;
    }

    .password-resize {
      margin-bottom: 200px;
    }
  }
  .borda {
    box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.02);
    background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
  }

  .v-stepper, .v-stepper__items, .v-stepper__content, .v-stepper__wrapper {
    overflow: visible !important;
  }

</style>
