<template>
  <v-container
    fluid
    fill-height
    :style="!!enabledStepMode ? { padding: 0 } : null"
  >
    <v-layout align-center justify-center wrap >
      <v-flex v-if="!enabledStepMode" xs12 sm6 md4 lg3>
        <Header/>
        <v-card :class="($resize && $mq.above(window.mobileSize) ? 'pa-5' : '')" :style="'margin-bottom: 40px; --access-account-color: ' + this.accessAccountTitleStyleColor">
          <v-card-text>
            <p class="access-account ">Criar Conta</p>
            <v-text-field name="email" label="E-mail" type="text" placeholder="Digite aqui seu e-mail" v-model="form.email"  :error-messages="emailErroValidation" outlined  readonly />
            <v-text-field name="name" label="Nome Completo" type="text" placeholder="Digite aqui seu nome completo" v-model="form.name" outlined/>
            <v-text-field
              name="password"
              label="Senha"
              placeholder="Digite aqui sua senha"
              :type="showingPassword ? 'text' : 'password'"
              :append-icon="`far ${showingPassword ? 'fa-eye-slash' : 'fa-eye'} grey--text`"
              @click:append="showingPassword = !showingPassword"
              v-model="form.password"
              outlined
              v-show="!createWithSocialAccount"
            />
            <v-text-field
                name="password"
                label="Confirmar sua senha"
                placeholder="Digite aqui novamente sua senha"
                v-model="form.passwordConfirmation"
                :hint="passwordHint"
                :error="passwordError"
                outlined
                v-show="!createWithSocialAccount"
                :type="showConfirmPassword ? 'text' : 'password'"
                :append-icon="`far ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'} grey--text`"
                @click:append="showConfirmPassword = !showConfirmPassword"/>
            <PasswordStrengthMeter :password="form.password" @update="(result) => passwordIsEnough = result" v-show="!createWithSocialAccount"  />
            <Recaptcha :siteKey="captchaSiteKey" @verify="verify" @error="invalidate" @expired="invalidate" :reload="true"/>
          </v-card-text>
          <v-card-actions>
            <v-btn block color="primary" height="48px" @click="register" :loading="busy" :disabled="canRegister()">Cadastrar</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn block color="secondary" class="black--text" height="48px" @click="cancel()" :disabled="busy" >Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex :style="{ ...gradientBackgroundStyle }" v-if="enabledStepMode">
        <v-stepper
          :style="{ ...stepsBackgroundStyle, height: '100vh' }"
          v-model="currentStep"
        >
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-flex align="start">
                <p
                  class="big-title-steps-mode mb-md-10"
                  :style="'--big-title-steps-color: ' + bigTitleStepStyle.color + '; --big-title-steps-font-size: ' + bigTitleStepStyle.fontSize"
                >
                  Digite seu nome completo
                </p>
              </v-flex>

              <v-flex class="name-resize" style="margin-top: 60px;">
                <v-text-field
                  name="name"
                  type="text"
                  v-model="form.name"
                />
              </v-flex>

              <v-layout :style="$vuetify.breakpoint.width < 351 && { marginTop: '70%' }" align-end>
                <v-row align="end">
                  <v-col class="pb-0" cols="12">
                    <v-btn
                      block
                      color="primary"
                      large
                      style="margin-bottom: 10px;"
                      class="login-btn-steps-mode"
                      @click="currentStep = signUpSteps.password"
                      :disabled="!form.name"
                    >
                      Próximo
                    </v-btn>
                  </v-col>
                  <v-col class="pt-0" cols="12">
                    <v-btn
                      @click="$router.go(-1)"
                      :color="this.accessAccountTitleStyleColor"
                      large
                      block
                      text
                    >
                      Voltar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-layout>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-flex align="start">
                <p
                  class="big-title-steps-mode mb-md-10"
                  :style="'--big-title-steps-color: ' + bigTitleStepStyle.color + '; --big-title-steps-font-size: ' + bigTitleStepStyle.fontSize"
                >
                  Crie uma senha
                </p>
              </v-flex>

              <v-flex class="password-resize" style="margin-top: 60px">
                <v-text-field
                  name="password"
                  :type="showingPassword ? 'text' : 'password'"
                  :append-icon="`far ${showingPassword ? 'fa-eye-slash' : 'fa-eye'} grey--text`"
                  @click:append="showingPassword = !showingPassword"
                  v-model="form.password"
                  v-show="!createWithSocialAccount"
                />

                <v-flex style="margin-top: 20px;">
                  <PasswordStrengthMeter :password="form.password" @update="(result) => passwordIsEnough = result" v-show="!createWithSocialAccount"  />
                </v-flex>
              </v-flex>

              <v-layout :style="$vuetify.breakpoint.width < 351 && { marginTop: '65%' }" align-end>
                <v-row align="end">
                  <v-col class="pb-0" cols="12">
                    <v-btn
                      block
                      color="primary"
                      large
                      style="margin-bottom: 10px;"
                      class="login-btn-steps-mode"
                      @click="currentStep = signUpSteps.confirmPassword"
                      :disabled="!passwordIsEnough"
                    >
                      Próximo
                    </v-btn>
                  </v-col>
                  <v-col class="pt-0" cols="12">
                    <v-btn
                      @click="currentStep = signUpSteps.name"
                      :color="this.accessAccountTitleStyleColor"
                      large
                      block
                      text
                    >
                      Voltar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-layout>
            </v-stepper-content>
            
            <v-stepper-content step="3">
              <v-flex align="start">
                <p
                  class="big-title-steps-mode mb-md-10"
                  :style="'--big-title-steps-color: ' + bigTitleStepStyle.color + '; --big-title-steps-font-size: ' + bigTitleStepStyle.fontSize"
                >
                  Confirme a senha
                </p>
              </v-flex>

              <v-flex class="confirm-password-resize" style="margin-top: 60px;">
                <v-text-field
                  name="password"
                  v-model="form.passwordConfirmation"
                  :error="passwordError"
                  v-show="!createWithSocialAccount"
                  clear-icon="fas fa-times grey--text"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  :append-icon="
                    !showConfirmPassword
                      ? 'far fa-eye grey--text'
                      : 'far fa-eye-slash grey--text'
                  "
                  @click:append="showConfirmPassword = !showConfirmPassword"
                />

                <v-flex style="margin-top: 20px;">
                  <PasswordStrengthMeter
                    :password="form.password"
                    @update="(result) => passwordIsEnough = result"
                    v-show="!createWithSocialAccount"
                  />

                  <Recaptcha :siteKey="captchaSiteKey" @verify="verify" @error="invalidate" @expired="invalidate" :reload="true"/>
                </v-flex>
              </v-flex>

              <v-layout :style="$vuetify.breakpoint.width < 351 && { marginTop: '25%' }" align-end>
                <v-row align="end">
                  <v-col class="pb-0" cols="12">
                    <v-btn
                      block
                      color="primary"
                      large
                      style="margin-bottom: 10px;"
                      class="login-btn-steps-mode"
                      @click="register"
                      :loading="busy"
                      :disabled="canRegister()"
                    >
                      Confirmar
                    </v-btn>
                  </v-col>
                  <v-col class="pt-0" cols="12">
                    <v-btn
                      @click="currentStep = signUpSteps.password"
                      :color="this.accessAccountTitleStyleColor"
                      large
                      block
                      text
                    >
                      Voltar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-layout>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </v-layout>
    <Footer/>
</v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { mask } from 'vue-the-mask';
import PasswordStrengthMeter from "@/components/password-strength-meter/index.vue";
import Recaptcha from '@/components/recaptcha/index.vue';
import { existsEmaillLogin } from '@/common/securityserver';
import Header from "../../components/security/Header";
import Footer from "../../components/security/Footer";


export default {
  name: "PrimeiroAcesso",
  components: { PasswordStrengthMeter, Recaptcha, Header, Footer },
  directives: { mask },
  data: () => ({
    invalidCredentials: true,
    busy: false,
    emailErroValidation: [],
    showingPassword: false,
    showConfirmPassword: false,
    passwordIsEnough: false,
    form: {
      name: "",
      email: "",
      password: "",
      passwordConfirmation: "",
    },
    currentStep: 1,
    signUpSteps: {
      name: 1,
      password: 2,
      confirmPassword: 3,
    },
    createWithSocialAccount: false,
  }),
  watch: {
    'form.email': {
      handler: function(val, oldVal) {
        this.validateLogin(val);
      },
    }
  },
  computed: {
    ...mapState([
      "window", "enabledStepMode", "accessAccountTitleStyleColor",
      "loginCardCustomStyle", "extraTitlesStyle", "bigTitleStepStyle",
      "smallTitleStepStyle", "stepsBackgroundStyle", "gradientBackgroundStyle",
    ]),
    isMobileSize() {
      return this.$resize && this.$mq.below(this.window.mobileSize);
    },
    passwordIsConfirmed() {
      return this.form.password === this.form.passwordConfirmation;
    },
    passwordMatches() {
      return (this.form.password === this.form.passwordConfirmation);
    },
    passwordHint() {
      return this.passwordMatches ? "" : "As senhas não coincidem";
    },
    passwordError() {
      return this.passwordMatches ? false : true;
    },
    captchaSiteKey(){
      return process.env.VUE_APP_CAPTCHA_SITE_KEY;
    },
    accessAccountTitleStyleColor(){
      return this.$store.state.accessAccountTitleStyleColor;
    },
  },
  methods: {
    ...mapMutations(['showmsg', 'enabledStepModeOn']),
    ...mapActions(["setValidateDevice", "userAuth"]),
    verify() {
      this.invalidCredentials = false;
    },
    invalidate() {
      this.invalidCredentials = true;
    },
    validateLogin(login){

      if(!login && this.createWithSocialAccount){
        this.emailErroValidation = `Vocẽ não possui e-mail vinculado a sua conta do ${this.$store.state.userServiceLogin.socialType}. Cadastre agora!`;
        return;
      }

      if(!login){
        this.emailErroValidation = 'E-mail é obrigatório';
        return;
      }

      if(!(/.+@.+\..+/.test(login))){
        this.emailErroValidation = 'E-mail inválido';
        return;
      }

      existsEmaillLogin(login).then(res =>{
        this.emailErroValidation = res.data ? ['E-mail não disponível'] : null
      });
    },
    canRegister() {
      if(this.createWithSocialAccount){
        return !(this.form.name && this.form.email && !this.invalidCredentials);
      }else{
        const formHasData = this.form.name && this.form.email && this.form.password && this.form.passwordConfirmation && !this.emailErroValidation;
        const passwordFillsConstraints = this.passwordIsConfirmed;

        return !(formHasData && passwordFillsConstraints && !this.invalidCredentials && this.passwordIsEnough);
      }
    },
    async register() {
      this.busy = true;
      this.form.login = this.form.email;

      await this.$store.dispatch('createUserFirstAccess', this.form)
              .finally(() => {
                this.userAuth(this.form);
                this.setValidateDevice(true);
                this.$router.go(-2);
                this.busy = false;
              });
    },
    cancel(){
      this.$store.commit('userServiceLogin', {});
      this.$router.go(-1);
    },
    loadSocialFields() {
      console.log(this.$store.state.userServiceLogin);
      if(this.$store.state.userServiceLogin && this.$store.state.userServiceLogin.socialType){
        this.createWithSocialAccount = true;

        // if(!this.$store.state.userServiceLogin.login)
        //   this.emailErroValidation = `Vocẽ não possui e-mail vinculado a sua conta do ${this.$store.state.userServiceLogin.socialType}. Cadastre agora!`;

        Object.assign(this.form, this.$store.state.userServiceLogin)
      }
    }
  },
  beforeMount() {
    this.form.email = this.$store.state.email;
    this.loadSocialFields();
  }
};
</script>

<style scoped>
  /* large */
  @media (min-height: 670px) and (min-width: 411px){
    .name-resize {
      margin-bottom: 350px;
    }

    .password-resize {
      margin-bottom: 300px;
    }

    .confirm-password-resize {
      margin-bottom: 200px;
    }
  }

  /* medium */
  @media (min-height: 700px) and (min-width: 350px) and (max-width: 410px){
    .name-resize {
      margin-bottom: 350px;
    }

    .password-resize {
      margin-bottom: 300px;
    }

    .confirm-password-resize {
      margin-bottom: 200px;
    }
  }

  /* small */
  @media (max-height: 699px) and (min-width: 350px) and (max-width: 410px){
    .name-resize {
      margin-bottom: 250px;
    }

    .password-resize {
      margin-bottom: 225px;
    }

    .confirm-password-resize {
      margin-bottom: 125px;
    }
  }
</style>
