import axios from "axios";

export default {
  state: {
    callbackServiceLogin: null,
    userRole: null,
    email: null,
    password: null,
    tinnyToken: null,
    token: null,
    appCode: null,
    appVersion: null,
    auth: null,
    appLogo: {
      src: null,
      width: null,
      mobileWidth: null,
    },
    enableAppLogo: true,
    footerLogo: null,
    appTitle: null,
    style: {
      backgroundColor: null,
      backgroundImage: null,
      backgroundSize: null,
      backgroundPosition: null
    },
    opacityStyle: {
      width: null,
      height: null,
      background: null
    },
    loginCardStyleColor: null,
    loginCardCustomStyle: {
      backdropFilter: null,
      borderTopRightRadius: null,
      borderTopLeftRadius: null,
      padding: null
    },
    stepsBackgroundStyle: null,
    gradientBackgroundStyle: null,
    enabledBgOpacity: null,
    titles: {
      cardTitle: 'Acesse sua conta',
      loginButtonTitle: 'Entrar',
      signUpButtonTitle: 'Cadastre-se',
      forgotPasswordTitle: 'Esqueceu sua senha?',
      extraTopCardTitle: null,
    },
    extraTitlesStyle: {
      color: null,
      fontSize: null,
    },
    bigTitleStepStyle: {
      color: null,
      fontSize: null,
    },
    smallTitleStepStyle: {
      color: null,
      fontSize: null,
    },
    enabledStepMode: false,
    enabledFaebookLogin: null,
    enabledGoogleLogin: null,
    createAccountStyleColor: null,
    accessAccountTitleStyleColor: null,
    accessAccountTitleStyleFontSize: '18px',
    enabledToCreateAccount: null,
    userServiceLogin: null,
    validateDevice: false,
  },
  mutations: {
    callbackServiceLogin(state, callbackServiceLogin) {
      state.callbackServiceLogin = callbackServiceLogin
    },
    userRole(state, userRole) {
      state.userRole = userRole
    },
    email(state, email) {
      state.email = email
    },
    token(state, token) {
      state.token = token
    },
    tinnyToken(state, tinnyToken) {
      state.tinnyToken = tinnyToken
    },
    password(state, password) {
      state.password = password
    },
    appCode(state, appCode) {
      state.appCode = appCode
    },
    appVersion(state, appVersion) {
      state.appVersion = appVersion
    },
    auth(state, auth) {
      state.auth = auth
    },
    backgroundColor(state, color){
      state.style.backgroundColor = color;
    },
    enabledStepModeOn(state, enable){
      state.enabledStepMode = enable;
    },
    enableAppLogoHeader(state, enable){
      state.enableAppLogo = enable;
    },
    stepsBackground(state, style){
      state.stepsBackgroundStyle = style;
    },
    gradientBackground(state, style){
      state.gradientBackgroundStyle = style;
    },
    backgroundImage(state, img){
      state.style.backgroundImage = 'url('+img+')';
      state.style.backgroundSize = 'cover';
      state.style.backgroundPosition = 'top center';
    },
    bgOpacityStyle(state, color){
      state.opacityStyle.width = '100%';
      state.opacityStyle.height = '100%';
      state.opacityStyle.background = color;
    },
    extraStepTitlesStyle(state, { color, fontSize }){
      state.extraTitlesStyle.color = color;
      state.extraTitlesStyle.fontSize = fontSize;
    },
    bigStepTitlesStyle(state, { color, fontSize }){
      state.bigTitleStepStyle.color = color;
      state.bigTitleStepStyle.fontSize = fontSize;
    },
    smallStepTitlesStyle(state, { color, fontSize }){
      state.smallTitleStepStyle.color = color;
      state.smallTitleStepStyle.fontSize = fontSize;
    },
    appLogo(state, logo){
      state.appLogo = logo;
    },
    appTitle(state, title){
      state.appTitle = title;
    },
    footerLogo(state, logo){
      state.footerLogo = logo;
    },
    enabledOpacity(state, enabledBgOpacity){
      state.enabledBgOpacity = enabledBgOpacity;
    },
    enabledFaebookLogin(state, enabledFaebookLogin){
      state.enabledFaebookLogin = enabledFaebookLogin;
    },
    enabledGoogleLogin(state, enabledGoogleLogin){
      state.enabledGoogleLogin = enabledGoogleLogin;
    },
    createAccountStyleColor(state, createAccountStyleColor){
      state.createAccountStyleColor = createAccountStyleColor;
    },
    loginCardColor(state, color){
      state.loginCardStyleColor = color;
    },
    loginCardStyle(state, style){
      state.loginCardCustomStyle.backdropFilter = style.backdropFilter;
      state.loginCardCustomStyle.padding = style.padding;
      state.loginCardCustomStyle.borderTopRightRadius = style.borderTopRightRadius;
      state.loginCardCustomStyle.borderTopLeftRadius = style.borderTopLeftRadius;
    },
    accessAccountTitleStyleColor(state, accessAccountTitleStyleColor){
      state.accessAccountTitleStyleColor = accessAccountTitleStyleColor;
    },
    accessAccountTitleFontSize(state, fontSize){
      state.accessAccountTitleStyleFontSize = fontSize;
    },
    enabledToCreateAccount(state, enabledToCreateAccount){
      state.enabledToCreateAccount = enabledToCreateAccount;
    },
    validateDevice(state, validate){
      state.validateDevice = validate;
    },
    profilePicture(state, profilePicture){
      state.userServiceLogin.profilePicture = profilePicture;
    },
    userServiceLogin(state, userServiceLogin){
      state.userServiceLogin = userServiceLogin;
    },
    cardTitle(state, cardTitle){
      state.titles.cardTitle = cardTitle;
    },
    loginButtonTitle(state, loginButtonTitle){
      state.titles.loginButtonTitle = loginButtonTitle;
    },
    signUpButtonTitle(state, signUpButtonTitle){
      state.titles.signUpButtonTitle = signUpButtonTitle;
    },
    extraTopTitle(state, extraTopCardTitle){
      state.titles.extraTopCardTitle = extraTopCardTitle;
    },
    forgotPasswordTitle(state, forgotPasswordTitle){
      state.titles.forgotPasswordTitle = forgotPasswordTitle;
    }
  },
  getters:{
    callbackServiceLogin: state => state.callbackServiceLogin,
    userRole: state => state.userRole,
    email: state => state.email,
    token: state => state.token,
    password: state => state.password,
    appCode: state => state.appCode,
    appVersion: state => state.appVersion,
    auth: state => state.auth,
    style: state => state.style,
    userServiceLogin: state => state.userServiceLogin,
    getTitles: state => state.titles,
    enabledBgOpacity: state => state.enabledBgOpacity,
    opacityStyle: state => state.opacityStyle,
    loginCardStyleColor: state => state.loginCardStyleColor,
    loginCardCustomStyle: state => state.loginCardCustomStyle,
    accessAccountTitleStyleFontSize: state => state.accessAccountTitleStyleFontSize,

  },
  actions:{
    setValidateDevice(context, validate){
      context.commit('validateDevice', validate);
    },
    callApiGateway(context, aconfig, responseType){
      const url = `${process.env.VUE_APP_SECURITY_SERVICE_URL}${aconfig.url}`;

      const requestSettings = {
        method: aconfig.method,
        url: url,
        params: aconfig.params,
        data: aconfig.data,
        headers: {
          Authorization: `Bearer ${context.getters.token}`,
          'Access-Control-Max-Age': 600,
          'Cache-Control': 'max-age=600',
        },
      };

      if (responseType) {
        requestSettings.responseType = responseType;
      }

      return axios(requestSettings);
    },
    callApiGatewayWithoutToken(context, aconfig, responseType){
      const url = `${process.env.VUE_APP_SECURITY_SERVICE_URL}${aconfig.url}`;

      const requestSettings = {
        method: aconfig.method,
        url: url,
        params: aconfig.params,
        data: aconfig.data
      };

      if (responseType) {
        requestSettings.responseType = responseType;
      }

      return axios(requestSettings);
    },
    setTheme(context, component){
      if(context.state.appCode === 'rh_protegido'){

        component.$vuetify.theme.themes.light.primary = '#5555A5';
        component.$vuetify.theme.themes.light.secondary = 'white';
        context.commit('backgroundColor', '#512198 !important');
        context.commit('backgroundImage', '/img/bg/bg-login-xipp-2.png');
        context.commit('appTitle', 'RH Protegido');
        context.commit('footerLogo', '/img/logo/xipp_logo_branco_fundo_transparente_pequena2.png');
        context.commit('createAccountStyleColor', component.$vuetify.theme.themes.light.secondary);
        context.commit('accessAccountTitleStyleColor', '#5555A5');
        context.commit('enabledToCreateAccount', true);

        let appLogo = {
          src: '/img/logo/xipp_icone-play_branco_fundo_transparente.png',
          width: '100px',
          mobileWidth: '80px',
        };

        context.commit('appLogo', appLogo);
      }

      else if(context.state.appCode === 'eu_protegido'){

        component.$vuetify.theme.themes.light.primary = '#5555A5';
        component.$vuetify.theme.themes.light.secondary = '#F4F8FF';
        context.commit('backgroundImage', '/img/bg/bg-euprotegido.png');
        context.commit('cardTitle', 'Você com o controle da sua saúde na palma da mão.');
        context.commit('signUpButtonTitle', 'Cadastrar');
        context.commit('forgotPasswordTitle', 'Atualizar senha');
        context.commit('extraTopTitle', 'Eu Protegido');

        context.commit('createAccountStyleColor', component.$vuetify.theme.themes.light.primary);
        context.commit('accessAccountTitleStyleColor', component.$vuetify.theme.themes.light.primary);
        context.commit('accessAccountTitleFontSize', '32px');
        context.commit('bgOpacityStyle', 'rgba(66, 81, 158, 0.2)');
        context.commit('loginCardStyle', {
          backdropFilter: 'blur(10px)',
          borderTopRightRadius: '40px',
          borderTopLeftRadius: '40px',
          padding: '20px'
        });
        context.commit('stepsBackground', {
          background: 'rgba(255, 255, 255, .8)',
          mixBlendMode: 'normal',
          backdropFilter: 'blur(53px)',
          padding: '10px'
        });
        context.commit('gradientBackground', {
          background: 'linear-gradient(164deg, rgba(55,51,178,.4) 0%, rgba(68,61,246,.4) 40%, rgba(254,123,147,.5) 75%, rgba(255,255,255,.4) 91%)',
        });
        context.commit('extraStepTitlesStyle', { color: component.$vuetify.theme.themes.light.primary, fontSize: '20px' });
        context.commit('bigStepTitlesStyle', { color: '#4A499B', fontSize: '28px' });
        context.commit('smallStepTitlesStyle', { color: '#868698', fontSize: '20px' });
        context.commit('loginCardColor', 'rgba(255, 255, 255, .7)');

        context.commit('enabledToCreateAccount', true);
        context.commit('enabledFaebookLogin', false);
        context.commit('enabledGoogleLogin', false);
        context.commit('enabledOpacity', true);
        context.commit('enabledStepModeOn', true);
        context.commit('enableAppLogoHeader', false);

        context.commit('appTitle', 'Eu Protegido');
        let appLogo = {
          src: '/img/logo/logo_euprotegido_com_texto_sem_fundo.png',
          width: '180px',
          mobileWidth: '100px',
        };

        context.commit('appLogo', appLogo);
      }

      else if(context.state.appCode === 'helpdesk'){

        component.$vuetify.theme.themes.light.primary = '#ea4965';
        component.$vuetify.theme.themes.light.secondary = 'white';
        context.commit('backgroundColor', '#2a244e !important');
        context.commit('backgroundImage', '/img/bg/bg-login.png');
        context.commit('appTitle', 'helpdesk');
        context.commit('footerLogo', '/img/logo/TNK Logo.png');
        context.commit('accessAccountTitleStyleColor', '#100d38');

        let appLogo = {
          src: '/img/logo/tnk_logo_cadeado_branco_e_rosa_fundo_transparente.png',
          width: '100px',
          mobileWidth: '80px',
        };

        context.commit('appLogo', appLogo);

      }

      else if(context.state.appCode === 'dr_protegido'){

        component.$vuetify.theme.themes.light.primary = '#5555A5';
        component.$vuetify.theme.themes.light.secondary = 'white';
        context.commit('backgroundColor', '#512198 !important');
        context.commit('backgroundImage', '/img/bg/bg-login-xipp-2.png');
        context.commit('appTitle', 'Dr Protegido');
        context.commit('footerLogo', '/img/logo/xipp_logo_branco_fundo_transparente_pequena2.png');
        context.commit('createAccountStyleColor', component.$vuetify.theme.themes.light.secondary);
        context.commit('accessAccountTitleStyleColor', '#5555A5');
        context.commit('enabledToCreateAccount', true);

        let appLogo = {
          src: '/img/logo/logo_drprotegido.png',
          width: '100px',
          mobileWidth: '80px',
        };

        context.commit('appLogo', appLogo);
      }
    }
  }
}
